section.company {
  padding: 100px;
}

.motion-container {
  width: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.company .title {
  font-size: 50px;
  line-height: 70px;
}

.company .description {
  font-size: 22px;
  margin-top: 50px;
}

.company .description .description-item:first-child {
  margin-bottom: 30px;
}

.common-center-style {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 780px) {
  .company .title {
    font-size: 38px;
    line-height: 70px;
  }

  section.company {
    font-size: 38px;
    padding: 80px 100px;
  }
}

@media (max-width: 480px) {
  .company .title {
    font-size: 30px;
    line-height: 40px;
  }

  section.company {
    padding: 80px 38px;
  }

  .company .description {
    font-size: 18px;
  }
}
