.wrapper {
  width: 100%;
  max-width: 1114px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
  margin-bottom: 64px;
}

.list-item {
  width: 100%;
  padding: 32px;
  border-bottom: 1px solid #cfcfcf;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  position: relative;
  align-items: center;
}

.list-item:hover {
  background-color: #fcfcfc96;
}

.title {
  text-overflow: ellipsis;
  max-height: 50px;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-right: 130px;
}

.expand-content {
  position: absolute;
  right: 32px;
  display: flex;
  align-items: center;
  color: #a0a0a0;
  font-size: 16px;
}

.date {
  margin-right: 16px;
}

@media (max-width: 780px) {
  .wrapper {
    width: 90vw;
    max-width: 100%;
  }

  .list-item {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .expand-content {
    position: static;
    font-size: 14px;
    margin-top: 8px;
  }

  .more-icon {
    display: none;
  }
}