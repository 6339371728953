.container {
  min-height: 100vh;
}

.common-center-style {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.banner-wrapper {
  position: relative;
  width: 100%;
  /* ! 아기상어 10주년 이벤트 Video로 Banner Video를 임시 변경하기에 주석 처리 추후 다시 Enable - Bigdeal */
  /* background-image: url('/images/PC_home_01.webp'); */
  background-position: center top;
  background-size: contain;
  background-repeat: no-repeat;
  aspect-ratio: 16 / 9;
}

.banner-wrapper video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@supports not (aspect-ratio: 16 / 9) {
  .banner-wrapper {
    padding-top: 56.25%;
  }
}
