.career {
  position: relative;
  width: 100%;
  background-image: url('/images/PC_home_02.webp');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  aspect-ratio: 640 / 147;
  margin-bottom: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@supports not (aspect-ratio: 640 / 147) {
  .career {
    padding-top: calc(100% / 640 * 147);
  }
}

.career .title {
  color: #ffffff;
  font-size: 30px;
  text-align: center;
  margin-bottom: 50px;
}

.career button {
  background-color: #ff66af;
  padding: 32px 72px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #ff66af;
  font-size: 24px;
}

@media (max-width: 1140px) {
  .career {
    background-image: url('/images/M_home_02.webp');
    aspect-ratio: 15 / 7;
  }

  @supports not (aspect-ratio: 15 / 7) {
    .career {
      padding-top: calc(100% / 15 * 7);
    }
  }
}

@media (max-width: 480px) {
  .career {
    aspect-ratio: 749 / 500;
    margin-bottom: 64px;
  }

  @supports not (aspect-ratio: 749 / 500) {
    .career {
      padding-top: calc(100% / 749 * 500);
    }
  }

  .career .title {
    font-size: 20px;
  }

  .career button {
    font-size: 16px;
    padding: 25px 30px;
  }
}
