section.business {
  padding: 100px 20px;
}

.common-center-style {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.business {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f7fcff;
}

.business .title {
  font-size: 30px;
  margin-bottom: 90px;
  text-align: center;
}

.business .card-wrapper {
  display: flex;
  justify-content: space-between;
}

.business .card {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 10px;
}

.business .card .title {
  font-size: 35px;
  padding: 0;
  margin: 0;
}

.business .card .description {
  font-size: 17px;
  text-align: center;
  margin: 20px 0;
}

.more {
  font-size: 16px;
  color: #a0a0a0;
}

@media (max-width: 1140px) {
  section.business {
    padding: 80px 20px;
  }

  .business .title {
    margin-bottom: 80px;
  }

  .business .card-wrapper {
    flex-direction: column;
  }

  .business .card .title {
    padding: 0;
    margin: 0;
  }

  .business .card {
    margin-bottom: 80px;
  }
}

@media (max-width: 480px) {
  .business .title {
    font-size: 18px;
  }

  .business .card .title {
    font-size: 20px;
  }

  .more {
    font-size: 14px;
    color: #a0a0a0;
  }
}
