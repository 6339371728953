.wrapper {
  width: 100vw;
  max-width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.list-item {
  width: 100%;
  height: 90.14px;
  border-top: 1px solid #cfcfcf;
}

@media (max-width: 1140px) {
}
