section.news {
  padding: 100px;
}

.news .title {
  font-size: 30px;
  margin-bottom: 90px;
  cursor: pointer;
}

.common-center-style {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media (max-width: 480px) {
  .news .title {
    font-size: 18px;
  }

  section.news {
    padding: 80px 38px;
  }
}
